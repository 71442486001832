import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { func, string } from 'prop-types';
import { Form, FieldTextInput, PrimaryButton } from '../../../components';
import { required } from '../../../util/validators';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './ReferenceCodeForm.module.css';

const ReferenceCodeFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        referenceCodeError,
        inProgress,
        referenceCodeValid,
      } = fieldRenderProps;

      const errorMessageMaybe = referenceCodeError ? (
        <FormattedMessage id="ReferenceCodeForm.errorMessage" />
      ) : null;
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || disabled || submitInProgress || referenceCodeError;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.code}
            type="text"
            id={formId ? `${formId}.code` : 'code'}
            name="code"
            label={intl.formatMessage({ id: 'ReferenceCodeForm.codeLabel' })}
            placeholder={intl.formatMessage({ id: 'ReferenceCodeForm.codePlaceholder' })}
            validate={required(intl.formatMessage({ id: 'ReferenceCodeForm.codeRequiredMessage' }))}
          />
          <p className={css.errorPlaceholder}>{errorMessageMaybe}</p>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={inProgress}
            disabled={submitDisabled}
            ready={referenceCodeValid}
          >
            {intl.formatMessage({ id: 'ReferenceCodeForm.submit' })}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ReferenceCodeFormComponent.defaultProps = {
  className: null,
  formId: 'ReferenceCodeForm',
};

ReferenceCodeFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const ReferenceCodeForm = injectIntl(ReferenceCodeFormComponent);

export default ReferenceCodeForm;
